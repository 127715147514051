import React, { useEffect, useState } from 'react'
import { FlexBox } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerLeftRightMedium, SpacerLeftRightSmall } from '../../../design/spacer/spacer'
import { BlogTopicBlockContainer, BlogTopicBlockTitle } from '../style/alchemist.styles'
import { IconCollection } from '../../../assets/data/icons.data'

export const BlogTopicBlock = (props) => {

    const [topicIconData, setTopicIconData] = useState([])

    useEffect(() => {
        setTopicIconData(IconCollection?.filter((item) => item.name === props.data?.toLowerCase())[0]?.icon)
    }, [props.data]);

    return (
        <a href={`/analects/${props.data.toLowerCase()}`} style={{ textDecoration: "none" }}>
            <BlogTopicBlockContainer
                style={{ backgroundColor: "#F9F3EC" }}
                onMouseEnter={props.compEntered}
                onMouseLeave={props.compLeave}>
                <FlexBox>
                    <div>
                        <ImgHolder
                            img={topicIconData}
                            width={32}
                        />
                    </div>
                    <SpacerLeftRightMedium />
                    <BlogTopicBlockTitle>
                        {props.data}
                    </BlogTopicBlockTitle>
                </FlexBox>
            </BlogTopicBlockContainer>
        </a>
    )
}
