import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const AlchemistWrapper = styled.div`
    background-color: #EEDFCE;
`;

export const AlchemistContainer = styled.div`
    background-color: #D9C9B7;
    opacity: 0.8;
    background-image:  radial-gradient(#826020 0.5px, transparent 0.5px), radial-gradient(#826020 0.5px, #D9C9B7 0.5px);
    background-size: 20px 20px;
    background-position: 0 0,10px 10px;
    border-radius: 8px;
`;

export const BlogInfoTileWrapper = styled.div`
    padding: 8px;
    border-radius: 4;
    width: 440px;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transition: all 0.5s ease;
    }

    @media only screen and (min-width: 1800px) {
       
    }
    @media only screen and (max-width: 1300px) {
       
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {
        
    }
    @media only screen and (max-width: 450px) {
        width: 100%;
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const AlchemistMainHeading = styled.div`
    font-family: 'DM Serif Display';
    font-size: ${(props) => props.theme.fontSizes.subHeadline};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 165px;
    letter-spacing: 0.02em;

    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
        line-height: 80px;
        letter-spacing: 0.02em;
    }
    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.subHeadline};
        line-height: 165px;
        letter-spacing: 0.02em;
    }
    @media only screen and (max-width: 650px) {
        text-align: center;
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
        line-height: 80px;
        letter-spacing: 0.02em;
    }
    @media only screen and (max-width: 450px) {
        font-size: 44px;
        line-height: 80px;
        letter-spacing: 0.02em;
    }
    @media only screen and (max-width: 370px) {
        font-size: 40px;
    }
`;

export const AlchemistMainSubHeading = styled.div`   
    font-family: 'Open Sans';
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: 33px;
`;

export const BlogInfoTileDateText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const BlogInfoTileHeading = styled.div`
    font-family: 'Libre Baskerville', serif;
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 28px;
`;

export const BlogInfoTileDescText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
`;

export const BlogTopicsWrapper = styled.div`
    background-color: rgba(150, 131, 109, 0.6);
    min-height: 400px;
`;

export const AlchemistHeading = styled.div`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const AlchemistSubHeading = styled.div`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.title};
    color: #F9F3EC;
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BlogTopicBlockContainer = styled.div`
   width: 240px;
   padding: 8px 16px;
   border-radius: 4px;
   cursor: pointer;

   &:hover {
        transform: scale(1.08);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

export const BlogTopicBlockTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
`;

export const BlogViewAllBtnContainer = styled.div`
    width: 120px;
    height: 36px;
    border-radius: 36px;
    background-color: #A88032;
    position: relative;
    cursor: pointer;
`;

export const BlogViewAllBtnText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const ContactFormWrapper = styled.div`
    
`;

export const ContactFormContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};
`;

export const ContactFormHeading = styled.div`
    font-family: 'Pacifico', cursive;
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    letter-spacing: 0.04em; 
`;

export const ContactFormLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
`;

export const ContactFormSubLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.light};
    letter-spacing: 0.02em;
    font-style: italic;
`;

export const ContactFormInput = styled.input`
    border: 1px solid #D8D9D9;
    width: 400px;
    height: 48px;
    padding: 4px 8px;
    border-radius: 2px;

    @media only screen and (max-width: 1100px) {
        width: 350px;
    }
    @media only screen and (max-width: 850px) {
        width: 100%;
    }
`;

export const ContactFormTextArea = styled.textarea`
    border: 1px solid #D8D9D9;
    width: 400px;
    padding: 4px 8px;
    border-radius: 2px;
    resize: none;
    vertical-align: top;
    line-height: 24px;

    @media only screen and (max-width: 1100px) {
        width: 350px;
    }
    @media only screen and (max-width: 850px) {
        width: 100%;
    }
`;
