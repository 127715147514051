import React from 'react'
import { ContactForm } from '../../alchemist/components/contactForm'
import { Container, FlexBoxSA } from '../../../global/style/global.styles'

export const BlogCTA = (props) => {
  return (
    <>
      <Container>
        <FlexBoxSA>
          <ContactForm
            width={props.width}
            location={props.location}
            variant="filled"
            inputFieldEntered={props.inputFieldEntered}
            inputFieldLeft={props.inputFieldLeft}
          />
        </FlexBoxSA>
      </Container>
    </>
  )
}
