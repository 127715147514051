import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Alchemist } from "./pages/alchemist/alchemist";
import useWindowDimensions from "./global/components/screenSize";
import { Blog } from "./pages/analects/screens/blog";
import { Analects } from "./pages/analects/analects";
import NotFound from "./pages/others/notFound";


function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  return (
    <>
      <Routes>
        <Route path="/" element={
          <Alchemist
            width={width}
            height={height}
            location={location}
          />} />
        <Route path="/:blogId" element={<Blog
          width={width}
          location={location}
        />} />
        <Route path="/analects/:filterId" element={
          <Analects
            width={width}
            height={height}
            location={location}
          />} />
        <Route path="*" element={<NotFound
          width={width}
          navigate={navigate}
          location={location}
        />} />
      </Routes>
    </>
  );
}

export default App;
