import marketingIcon from '../icons/marketing.png'
import technologyIcon from '../icons/technology.png'
import brandingIcon from '../icons/branding.png'

export const IconCollection = [
    {
        id: 1,
        name: "marketing",
        icon: marketingIcon
    },
    {
        id: 2,
        name: "technology",
        icon: technologyIcon
    },
    {
        id: 3,
        name: "branding",
        icon: brandingIcon
    },
    {
        id: 4,
        name: "marketing trends",
        icon: marketingIcon
    },
    {
        id: 5,
        name: "web design",
        icon: brandingIcon
    },
    {
        id: 6,
        name: "web-dev trends",
        icon: technologyIcon
    }
]