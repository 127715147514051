import React, { useEffect, useState } from 'react'
import { AlchemistHomeContainer, AnalectsHeading, AnalectsSectionContainer, AnalectsSectionWrapper } from '../style/analects.styles'
import { AbsoluteCenter, Container, FlexBoxSA, SandwichRealHalf, Squeezer } from '../../../global/style/global.styles'
import { BlogInfoTile } from '../../alchemist/components/blogInfoTile'
import { Grid } from '@mui/material'
import { SpacerTopBottomLarge, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

export const AnalectsSection = (props) => {

    const [analectsData, setAnalectsData] = useState([])

    useEffect(() => {
        if (props.filterId === "all") {
            setAnalectsData(props.blogs?.filter((x) => x.status === "active").sort(function (a, b) { return new Date(b.date) - new Date(a.date) }))
        } else {
            setAnalectsData(props.blogs?.sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
                .filter((x) => x.status === "active" && x.topic.toLowerCase() === props.filterId))
        }
    }, [props.blogs])

    return (
        <AnalectsSectionWrapper>
            <AnalectsSectionContainer>
                <AnalectsHeading>
                    {
                        props.filterId === "all"
                            ?
                            "analects"
                            :
                            props.filterId
                    }
                </AnalectsHeading>
            </AnalectsSectionContainer>
            {/* {
                props.width > 850
                    ?
                    <SandwichRealHalf />
                    :
                    props.width > 650
                    &&
                    <SpacerTopBottomLarge />
            } */}
            <FlexBoxSA>
                <AlchemistHomeContainer
                    onMouseEnter={props.compEntered}
                    onMouseLeave={props.compLeave}
                    href='/'>
                    <AbsoluteCenter>
                        <HomeRoundedIcon
                            fontSize='large'
                            sx={{
                                color: "#96836D"
                            }}
                        />
                    </AbsoluteCenter>
                </AlchemistHomeContainer>
            </FlexBoxSA>
            <SandwichRealHalf />
            <Squeezer>
                <Grid container spacing={4}>
                    {
                        analectsData?.map((item, index) => (
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                <BlogInfoTile
                                    width={props.width}
                                    location={props.location}
                                    data={item}
                                    compEntered={props.compEntered}
                                    compLeave={props.compLeave}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Squeezer>
        </AnalectsSectionWrapper>
    )
}
