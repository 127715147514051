import React, { useEffect, useState } from 'react'
import { FlexBox, GenContainer } from '../../../global/style/global.styles'
import { Grid } from '@mui/material'
import { ImgHolder } from '../../../global/components/imgHolder'
import alchemistLogo from '../../../assets/icons/alchemistLogo.png'
import { BlogInfoTile } from './blogInfoTile'
import { SpacerBottomLarge, SpacerTopBottomSmall, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { AlchemistMainHeading, AlchemistMainSubHeading } from '../style/alchemist.styles'

export const AlchemistHero = (props) => {

    const [featuredBlogData, setFeaturedBlogData] = useState([])

    useEffect(() => {
        setFeaturedBlogData(props.blogs?.filter((item) => item.status === "active" && item.feature > 0))
    }, [props.blogs]);

    return (
        <>
            <GenContainer>
                <Grid container spacing={props.width > 850 ? 4 : 6}>
                    <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                            {/* <ImgHolder
                                img={alchemistLogo}
                                width={props.width > 1800 ? 500 : props.width > 1100 ? 440 : props.width > 450 ? 360 : 240}
                            /> */}
                        </FlexBox>
                        <div>
                            <AlchemistMainHeading>
                                ALCHEMIST
                            </AlchemistMainHeading>
                            <AlchemistMainSubHeading style={{ textAlign: "right" }}>
                                Blog for all things Innovative
                            </AlchemistMainSubHeading>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={7} sm={12} xs={12}>
                        {
                            featuredBlogData?.sort((a, b) => a.feature - b.feature).slice(0, 2).map((item, index) => (
                                <FlexBox
                                    style={{
                                        justifyContent: props.width > 850 ? "normal" : "space-around",
                                        paddingBottom: props.width > 850 ? 20 : 40
                                    }}
                                    key={index}>
                                    <BlogInfoTile
                                        width={props.width}
                                        location={props.location}
                                        data={item}
                                        compEntered={props.compEntered}
                                        compLeave={props.compLeave}
                                    />
                                </FlexBox>
                            ))
                        }
                    </Grid>
                </Grid>
            </GenContainer>
        </>
    )
}
