import React, { useEffect, useState } from 'react'
import { FlexBox, GenContainer } from '../../../global/style/global.styles'
import { AlchemistHeading, AlchemistSubHeading, BlogTopicsWrapper } from '../style/alchemist.styles'
import { SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Grid } from '@mui/material'
import { BlogTopicBlock } from './blogTopicBlock'

export const BlogTopics = (props) => {

    const [blogTopicsData, setBlogTopicsData] = useState([])

    useEffect(() => {
        setBlogTopicsData([...new Set(props.blogs?.filter((x) => x.status === "active").map((item) => item.topic))])
    }, [props.blogs]);

    return (
        <BlogTopicsWrapper>
            <GenContainer>
                <AlchemistSubHeading>
                    Find blogs by topic
                </AlchemistSubHeading>
                <SpacerTopBottomXXXL />
                <Grid container spacing={2}>
                    {
                        blogTopicsData?.map((item, index) => (
                            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                                <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                                    <BlogTopicBlock
                                        width={props.width}
                                        location={props.location}
                                        data={item}
                                        compEntered={props.compEntered}
                                        compLeave={props.compLeave}
                                    />
                                </FlexBox>
                            </Grid>
                        ))
                    }
                </Grid>
            </GenContainer>
        </BlogTopicsWrapper>
    )
}
