import React from 'react'
import { AlchemistHomeContainer, AlchemistHomePosition, BlogContainer, BlogHeroDatePosition, BlogHeroDateText, BlogHeroDurationPosition, BlogHeroDurationText, BlogHeroHeading, BlogHeroHeadingPosition, BlogHeroTopicPosition, BlogHeroTopicText, BlogHeroWrapper } from '../style/analects.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { AbsoluteCenter } from '../../../global/style/global.styles'
import moment from 'moment'
import { Layer } from '../../../global/components/layer'

export const BlogHero = (props) => {

    return (
        <>
            <BlogHeroWrapper>
                <ImgHolder
                    img={
                        props.width > 1100
                            ?
                            props.banner169
                            :
                            props.width > 650
                                ?
                                props.banner54
                                :
                                props.banner34
                    }
                    width="100%"
                    height="100%"
                />
                <Layer
                    shade={
                        props.heroTextColor === "white"
                        &&
                        "rgba(0, 0, 0, 0.1)"
                    }
                />
                <BlogHeroTopicPosition>
                    <BlogHeroTopicText style={{ color: props.heroTextColor }}>
                        {props.topic?.toUpperCase()}
                    </BlogHeroTopicText>
                </BlogHeroTopicPosition>
                <BlogHeroHeadingPosition>
                    <BlogHeroHeading style={{ color: props.heroTextColor }}>
                        {props.heading}
                    </BlogHeroHeading>
                </BlogHeroHeadingPosition>
                <BlogHeroDatePosition>
                    <BlogHeroDateText style={{ color: props.heroTextColor }}>
                        {props.heading && moment(props.date).format("DD MMM YYYY")}
                    </BlogHeroDateText>
                </BlogHeroDatePosition>
                <BlogHeroDurationPosition>
                    <BlogHeroDurationText style={{ color: props.heroTextColor }}>
                        {`${props.duration} min`}
                    </BlogHeroDurationText>
                </BlogHeroDurationPosition>
            </BlogHeroWrapper>
        </>
    )
}