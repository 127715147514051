import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const AnalectsWrapper = styled.div`
    background-color: #EEDFCE;
    opacity: 0.8;
    background-image:  radial-gradient(#826020 0.5px, transparent 0.5px), radial-gradient(#826020 0.5px, #EEDFCE 0.5px);
    background-size: 20px 20px;
    background-position: 0 0,10px 10px;
`;

export const AnalectsSectionWrapper = styled.div`
    min-height: 100vh;
`;

export const AnalectsSectionContainer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    position: fixed;
    z-index: -1;
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {
        padding-bottom: 120px;
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
        padding-bottom: 72px;
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const AnalectsHeading = styled.div`
    font-family: 'Lato', sans-serif;
    -webkit-text-stroke: 4px #96836D;
    color: #FFFAF5;
    font-size: 160px;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 0.02em;
    position: absolute;
    line-height: 160px;

    @media only screen and (max-width: 1100px) {
        font-size: 180px;
        line-height: 136px;
        font-size: 200px;
    }
    @media only screen and (max-width: 850px) {
        font-size: 140px;
        line-height: 104px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
        line-height: 72px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 64px;
        line-height: 48px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 56px;
    }
`;

export const BlogWrapper = styled.div`
   
`;

export const BlogContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[31]};
    padding-right: ${(props) => props.theme.space[31]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: 320px;
        padding-right: 320px;
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[20]};
        padding-right: ${(props) => props.theme.space[20]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[16]};
        padding-right: ${(props) => props.theme.space[16]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const BlogHeroWrapper = styled.div`
    height: 100vh;
    position: relative;
`;

export const BlogHeroHeading = styled.div`
    font-family: 'Libre Baskerville', serif;
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 48px;
    max-width: 900px;

    @media only screen and (min-width: 1800px) {
        font-size: 48px;
        line-height: 64px;
        max-width: 1200px;
    }
    @media only screen and (max-width: 1300px) {
    }
    @media only screen and (max-width: 1100px) {
    }
    @media only screen and (max-width: 850px) {
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const BlogHeroHeadingPosition = styled.div`
    position: absolute;
    left: ${(props) => props.theme.space[31]};
    top: 50%;
    transform: translate(0,-50%);

    @media only screen and (min-width: 1800px) {
        left: 320px;
    }
    @media only screen and (max-width: 1300px) {
        left: ${(props) => props.theme.space[20]};
    }
    @media only screen and (max-width: 1100px) {
        left: ${(props) => props.theme.space[16]};
    }
    @media only screen and (max-width: 850px) {
        left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 650px) {
        left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 450px) {
        left: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        left: ${(props) => props.theme.space[1]};
    }
`;

export const BlogHeroTopicText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const BlogHeroTopicPosition = styled.div`
    position: absolute;
    left: ${(props) => props.theme.space[31]};
    top: 30%;
    transform: translate(0,-50%);

    @media only screen and (min-width: 1800px) {
        left: 320px;
    }
    @media only screen and (max-width: 1300px) {
        left: ${(props) => props.theme.space[20]};
    }
    @media only screen and (max-width: 1100px) {
        left: ${(props) => props.theme.space[16]};
    }
    @media only screen and (max-width: 850px) {
        left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 650px) {
        top: 10%;
        left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 450px) {
        top: 20%;
        left: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        left: ${(props) => props.theme.space[1]};
    }
`;

export const BlogHeroDateText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const BlogHeroDatePosition = styled.div`
    position: absolute;
    left: ${(props) => props.theme.space[31]};
    bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
        left: 320px;
    }
    @media only screen and (max-width: 1300px) {
        left: ${(props) => props.theme.space[20]};
    }
    @media only screen and (max-width: 1100px) {
        left: ${(props) => props.theme.space[16]};
    }
    @media only screen and (max-width: 850px) {
        left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 650px) {
        left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 450px) {
        left: ${(props) => props.theme.space[4]};
        bottom: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 370px) {
        left: ${(props) => props.theme.space[1]};
    }
`;

export const BlogHeroDurationText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const BlogHeroDurationPosition = styled.div`
    position: absolute;
    right: ${(props) => props.theme.space[31]};
    bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
        right: 320px;
    }
    @media only screen and (max-width: 1300px) {
        right: ${(props) => props.theme.space[20]};
    }
    @media only screen and (max-width: 1100px) {
        right: ${(props) => props.theme.space[16]};
    }
    @media only screen and (max-width: 850px) {
        right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 650px) {
        right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 450px) {
        right: ${(props) => props.theme.space[4]};
        bottom: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 370px) {
        right: ${(props) => props.theme.space[1]};
    }
`;

export const BlogContentPara = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.04em;
    line-height: 36px;
    text-align: justify;

    @media only screen and (max-width: 850px) {
        text-align: left;
    }
`;

export const BlogRecommendWrapper = styled.div`
    background-color: #D9C9B7;
    opacity: 0.8;
    background-image:  radial-gradient(#826020 0.5px, transparent 0.5px), radial-gradient(#826020 0.5px, #D9C9B7 0.5px);
    background-size: 20px 20px;
    background-position: 0 0,10px 10px;
    border-radius: 8px;
`;

export const BlogContentTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    letter-spacing: 0.05em;
    line-height: 40px;
`;

export const BlogContentSubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    letter-spacing: 0.04em;
    line-height: 36px;
`;

export const BlogContentLi = styled.li`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    letter-spacing: 0.04em;
    line-height: 36px;
    padding: 4px 0;
`;

export const BlogContentImg = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const AlchemistHomeContainer = styled.a`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid #96836D;
    position: relative;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
`;