import React from 'react'
import { IconHolder } from './iconHolder'
import LOGOBLACK from "../../assets/inspirise/logo/logoBlack.png"
import LOGOWHITE from "../../assets/inspirise/logo/logoWhite.png"
import { NavLink } from 'react-router-dom'

export const LogoBuild = (props) => {
    return (
        <>
            <NavLink
                to={props.link ? props.link : "https://www.inspirise.com/"}
                target='_blank'
                onMouseEnter={props.logoEntered}
                onMouseLeave={props.logoLeave}
                onClick={props.clicked}>
                <IconHolder
                    icon={props.color === "white" ? LOGOWHITE : LOGOBLACK}
                    width={props.width}
                    height={props.height}
                />
            </NavLink>
        </>
    )
}
