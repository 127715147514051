import React, { useContext, useEffect, useState } from 'react'
import { Container, FlexBox, GenContainer } from '../../../global/style/global.styles'
import { AlchemistSubHeading } from '../../alchemist/style/alchemist.styles'
import { SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { BlogInfoTile } from '../../alchemist/components/blogInfoTile'
import { BlogRecommendWrapper } from '../style/analects.styles'
import { ApiContext } from '../../../context/api/api.provider'

export const BlogRecommend = (props) => {

    const { blogs } = useContext(ApiContext)

    const [similarBlogsData, setSimilarBlogsData] = useState([])
    const [recentBlogsData, setRecentBlogsData] = useState([])

    useEffect(() => {
        setSimilarBlogsData(blogs?.filter((x) => x.status === "active" && x.id !== props.data?.id && x.topic === props.data?.topic)
            .sort(function (a, b) { return new Date(b.date) - new Date(a.date) }))
        setRecentBlogsData(blogs?.filter((x) => x.status === "active" && x.id !== props.data?.id)
            .sort(function (a, b) { return new Date(b.date) - new Date(a.date) }))
    }, [blogs])

    return (
        <BlogRecommendWrapper style={{ overflow: "auto" }}>
            <Container>
                <AlchemistSubHeading style={{ color: "white" }}>
                    {
                        similarBlogsData.length > 0 ? "Explore similar blogs" : "Explore recent blogs"
                    }
                </AlchemistSubHeading>
                <SpacerTopBottomXXXL />
                <FlexBox>
                    {
                        similarBlogsData.length > 0
                            ?
                            similarBlogsData.map((item, index) => (
                                <div key={index} style={{
                                    flexShrink: 0,
                                    marginRight: 40,
                                    maxWidth: props.width > 1800 ? 650 : props.width > 1300 ? 580 : props.width > 1100 ? 500 : props.width > 850 ? 460 : props.width > 650 ? 640 : props.width > 450 ? 420 : props.width > 370 ? 340 : 300
                                }}>
                                    <BlogInfoTile
                                        width={props.width}
                                        location={props.location}
                                        data={item}
                                        compEntered={props.compEntered}
                                        compLeave={props.compLeave}
                                    />
                                </div>
                            ))
                            :
                            recentBlogsData.map((item, index) => (
                                <div key={index} style={{
                                    flexShrink: 0,
                                    marginRight: 40,
                                    maxWidth: props.width > 1800 ? 650 : props.width > 1300 ? 580 : props.width > 1100 ? 500 : props.width > 850 ? 460 : props.width > 650 ? 640 : props.width > 450 ? 420 : props.width > 370 ? 340 : 300
                                }}>
                                    <BlogInfoTile
                                        width={props.width}
                                        location={props.location}
                                        data={item}
                                        compEntered={props.compEntered}
                                        compLeave={props.compLeave}
                                    />
                                </div>
                            ))
                    }
                </FlexBox>
            </Container>
        </BlogRecommendWrapper>
    )
}
