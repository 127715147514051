import React, { useContext, useRef, useState } from 'react'
import { ContactFormContainer, ContactFormHeading, ContactFormInput, ContactFormLabel, ContactFormSubLabel, ContactFormTextArea, ContactFormWrapper } from '../style/alchemist.styles'
import { AbsoluteCenter, FlexBox, FlexBoxSB } from '../../../global/style/global.styles'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL, SpacerTopBottomXXL } from '../../../design/spacer/spacer'
import { Alert, Button, IconButton, Snackbar } from '@mui/material'
import { ArchiveContext } from '../../../context/archive/archive.provider'
import { ApiContext } from '../../../context/api/api.provider'
import emailjs from '@emailjs/browser';
import CloseIcon from '@mui/icons-material/Close';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import '../../../global/style/global.css'
import { addDoc } from 'firebase/firestore'

export const ContactForm = (props) => {

    const form = useRef();

    const { leadsCollectionRef, loading, setLoading } = useContext(ApiContext);
    const {
        name,
        setName,
        phone,
        setPhone,
        email,
        setEmail,
        comName,
        setComName,
        req,
        setReq
    } = useContext(ArchiveContext);

    const notifyCXO = () => {

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleSubmit = async () => {
        setLoading(true);

        const data = {
            name: name,
            phone: phone,
            email: email,
            comName: comName,
            req: req,
            captured: props.location.pathname,
            createdAt: new Date()
        }

        await addDoc(leadsCollectionRef, data);

        setLoading(false);
        notifyCXO(data);
        setName("");
        setEmail("");
        setPhone("");
        setReq("");
        setComName("");
        handleClick();
    }

    return (
        <>
            <ContactFormWrapper
                style={
                    props.variant === "filled"
                        ?
                        {
                            backgroundColor: "rgba(201, 201, 201, 0.3)",
                            borderRadius: 8
                        }
                        :
                        {}
                }>
                <ContactFormContainer>
                    <form ref={form}>
                        <ContactFormHeading style={{ color: props.color === "white" ? "white" : "black" }}>
                            We would love to help!
                        </ContactFormHeading>
                        <SpacerTopBottomXL />
                        {
                            props.width > 650
                                ?
                                <>
                                    <FlexBoxSB>
                                        <div>
                                            <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                                Name*
                                            </ContactFormLabel>
                                            <SpacerTopBottomMedium />
                                            <ContactFormInput
                                                style={{ width: 197, height: 40 }}
                                                name='name'
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <SpacerLeftRightMedium />
                                        <div>
                                            <FlexBoxSB>
                                                <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                                    Company
                                                </ContactFormLabel>
                                                <SpacerLeftRightSmall />
                                                <ContactFormSubLabel style={{ color: props.color === "white" ? "#C9C9C9" : "#6E6D70" }}>
                                                    (optional)
                                                </ContactFormSubLabel>
                                            </FlexBoxSB>
                                            <SpacerTopBottomMedium />
                                            <ContactFormInput
                                                style={{ width: 197, height: 40 }}
                                                name="comName"
                                                value={comName}
                                                onChange={(e) => setComName(e.target.value)}
                                            />
                                        </div>
                                    </FlexBoxSB>
                                    <SpacerTopBottomXL />
                                    <FlexBoxSB>
                                        <div>
                                            <FlexBoxSB>
                                                <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                                    Phone
                                                </ContactFormLabel>
                                                <SpacerLeftRightSmall />
                                                <ContactFormSubLabel style={{ color: props.color === "white" ? "#C9C9C9" : "#6E6D70" }}>
                                                    (quicker response)
                                                </ContactFormSubLabel>
                                            </FlexBoxSB>
                                            <SpacerTopBottomMedium />
                                            <PhoneInput
                                                defaultCountry="IN"
                                                keyboardType="phone-pad"
                                                name="phone"
                                                value={phone}
                                                onChange={setPhone} />
                                        </div>
                                        <SpacerLeftRightMedium />
                                        <div>
                                            <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                                Email*
                                            </ContactFormLabel>
                                            <SpacerTopBottomMedium />
                                            <ContactFormInput
                                                style={{ width: 197, height: 40 }}
                                                name='email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </FlexBoxSB>
                                    <SpacerTopBottomXL />
                                </>
                                :
                                <>
                                    <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                        Name*
                                    </ContactFormLabel>
                                    <SpacerTopBottomMedium />
                                    <ContactFormInput
                                        style={{ width: "100%", height: 40 }}
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <SpacerTopBottomXL />
                                    <FlexBox>
                                        <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                            Phone
                                        </ContactFormLabel>
                                        <SpacerLeftRightSmall />
                                        <ContactFormSubLabel>
                                            (quicker response)
                                        </ContactFormSubLabel>
                                    </FlexBox>
                                    <SpacerTopBottomMedium />
                                    {/* <ContactFormInput
                            style={{ width: "100%", height: 40 }}
                            name='phone'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        /> */}
                                    <PhoneInput
                                        defaultCountry="IN"
                                        keyboardType="phone-pad"
                                        name="phone"
                                        value={phone}
                                        onChange={setPhone} />
                                    <SpacerTopBottomXL />
                                    <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                        Email*
                                    </ContactFormLabel>
                                    <SpacerTopBottomMedium />
                                    <ContactFormInput
                                        style={{ width: "100%", height: 40 }}
                                        name='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <SpacerTopBottomXL />
                                    <FlexBox>
                                        <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                                            Company
                                        </ContactFormLabel>
                                        <SpacerLeftRightSmall />
                                        <ContactFormSubLabel style={{ color: props.color === "white" ? "#C9C9C9" : "#6E6D70" }}>
                                            (optional)
                                        </ContactFormSubLabel>
                                    </FlexBox>
                                    <SpacerTopBottomMedium />
                                    <ContactFormInput
                                        style={{ width: "100%", height: 40 }}
                                        name="comName"
                                        value={comName}
                                        onChange={(e) => setComName(e.target.value)}
                                    />
                                    <SpacerTopBottomXL />
                                </>
                        }
                        <ContactFormLabel style={{ color: props.color === "white" ? "white" : "black" }}>
                            Requirements
                        </ContactFormLabel>
                        <SpacerTopBottomMedium />
                        <ContactFormTextArea
                            rows={5}
                            style={{ width: "100%" }}
                            name="req"
                            value={req}
                            onChange={(e) => setReq(e.target.value)}
                        />
                        <SpacerTopBottomXXL />
                        <Button
                            variant='contained'
                            color='success'
                            fullWidth
                            onMouseEnter={props.inputFieldEntered}
                            onMouseLeave={props.inputFieldLeft}
                            disabled={!name || !email}
                            onClick={handleSubmit}>
                            Submit
                        </Button>
                    </form>
                </ContactFormContainer>
            </ContactFormWrapper>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Thank you!, One of our associates will get back to you shortly.
                </Alert>
            </Snackbar>
        </>
    )
}