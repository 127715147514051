import React, { createContext, useState } from 'react'
import { useRef } from 'react';

export const ArchiveContext = createContext()

export const ArchiveProvider = ({ children }) => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comName, setComName] = useState("");
    const [req, setReq] = useState("");

    const emailLink = "mailto:hello@inspirise.com"
    const youTubeLink = "https://www.youtube.com/@inspirise"
    const linkedInLink = "https://www.linkedin.com/company/inspirise-innovations/"
    const facebookLink = "https://www.facebook.com/inspirise.innovations/"
    const twitterLink = "https://twitter.com/Inspirise_"
    const whatsappLink = "https://wa.me/message/B6CJALBHFUT3I1"
    const instagramLink = "https://www.instagram.com/inspirise_innovations/"
    const pinterestLink = "https://in.pinterest.com/Inspirise_Innovations/"
    const quoraLink = "https://www.quora.com/profile/Inspirise"
    const redditLink = "https://www.reddit.com/user/Inspirise"
    const lineLink = "https://line.me/ti/p/k6TXVv436j"
    const discordLink = "https://discord.gg/hBSKENJW"
    const twitchlink = "https://www.twitch.tv/inspirise_innovations"
    const tumblrLink = "www.tumblr.com/inspirise"
    const dribbbleLink = "https://dribbble.com/Inspirise"
    const behanceLink = "https://www.behance.net/inspirise"
    const patreonLink = "https://patreon.com/Inspirise"
    const googleMapsLink = "https://maps.app.goo.gl/5xFdi6HvYvjnRTpX8"
    const telegramLink= "https://t.me/inspirise"


    return (
        <ArchiveContext.Provider
            value={{
                name,
                setName,
                phone,
                setPhone,
                email,
                setEmail,
                comName,
                setComName,
                req,
                setReq,
                emailLink,
                youTubeLink,
                linkedInLink,
                facebookLink,
                twitterLink,
                whatsappLink,
                instagramLink,
                pinterestLink,
                quoraLink,
                redditLink,
                lineLink,
                discordLink,
                twitchlink,
                tumblrLink,
                dribbbleLink,
                behanceLink,
                patreonLink,
                googleMapsLink,
                telegramLink
            }}
        >
            {children}
        </ArchiveContext.Provider>
    )
}