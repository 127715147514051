import React, { useEffect, useState } from 'react'
import { FlexBoxSA, GenContainer, SandwichRealHalf } from '../../../global/style/global.styles'
import { AlchemistHeading } from '../style/alchemist.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Grid } from '@mui/material'
import { BlogInfoTile } from './blogInfoTile'
import { BlogViewAllBtn } from './blogViewAllBtn'

export const RecentBlogs = (props) => {

    const [recentBlogsData, setRecentBlogsData] = useState([])

    useEffect(() => {
        setRecentBlogsData(props.blogs?.filter((x) => x.status === "active").sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
            .slice(0, 4))
    }, [props.blogs])

    return (
        <>
            <GenContainer>
                <AlchemistHeading>
                    Recent blogs
                </AlchemistHeading>
                <SpacerTopBottomXXXL />
                <Grid container spacing={4}>
                    {
                        recentBlogsData.map((item, index) => (
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                <BlogInfoTile
                                    width={props.width}
                                    location={props.location}
                                    data={item}
                                    compEntered={props.compEntered}
                                    compLeave={props.compLeave}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <SandwichRealHalf />
                <FlexBoxSA>
                    <BlogViewAllBtn
                        compEntered={props.compEntered}
                        compLeave={props.compLeave}
                    />
                </FlexBoxSA>
            </GenContainer>
        </>
    )
}
