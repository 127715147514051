import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BlogWrapper } from '../style/analects.styles';
import { Navbar } from '../../../global/components/navigation/navbar';
import ScrollToTop from '../../../global/components/scrollToTop';
import { motion } from 'framer-motion'
import { Footer } from '../../../global/components/footer';
import { BlogRecommend } from '../components/blogRecommend';
import { BlogCTA } from '../components/blogCTA';
import { BlogHero } from '../components/blogHero';
import { BlogContent } from '../components/blogContent';
import { ApiContext } from '../../../context/api/api.provider';

export const Blog = (props) => {

  const { blogs } = useContext(ApiContext)

  const { blogId } = useParams();

  const [blogData, setBlogData] = useState([])

  useEffect(() => {
    setBlogData(blogs?.filter((item) => item.status === "active" && item.id === blogId)[0])
  }, [blogs])


  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {

    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  return (
    <BlogWrapper>
      <ScrollToTop />
      <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
        <Navbar
          color={blogData?.heroTextcolor}
          logoEntered={compEntered}
          logoLeave={compLeave}
          navBarEntered={compEntered}
          navBarLeave={compLeave}
        />
      </div>
      <BlogHero
        width={props.width}
        heading={blogData?.heading}
        subHeading={blogData?.subHeading}
        author={blogData?.author}
        duration={blogData?.duration}
        topic={blogData?.topic}
        banner169={blogData?.dp?.filter((x) => x.type === "banner169")[0].url}
        banner54={blogData?.dp?.filter((x) => x.type === "banner54")[0].url}
        banner34={blogData?.dp?.filter((x) => x.type === "banner34")[0].url}
        heroTextColor={blogData?.heroTextcolor}
        date={new Date()}
      />
      <BlogContent
        width={props.width}
        format={blogData?.format}
        content={blogData?.content}
      />
      <BlogCTA
        width={props.width}
        data={blogData}
        compEntered={compEntered}
        compLeave={compLeave}
      />
      <BlogRecommend
        width={props.width}
        location={props.location}
        compEntered={compEntered}
        compLeave={compLeave}
      />
      <Footer
        footerEntered={compEntered}
        footerLeave={compLeave}
        backToTopBtnEntered={compEntered}
        backToTopBtnLeave={compLeave}
        inputFieldEntered={compEntered}
        inputFieldLeft={compLeave}
      />
      {
        props.width > 850
        &&
        <motion.div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "black",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            pointerEvents: "none"
          }}
          variants={variants}
          animate={cursorVariant}
        />
      }
    </BlogWrapper>
  )
}
