import React from 'react'
import { FlexBox, FlexColumnSB } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerBottomMedium, SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerRightLarge, SpacerRightXL, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { BlogInfoTileDateText, BlogInfoTileDescText, BlogInfoTileHeading, BlogInfoTileWrapper } from '../style/alchemist.styles'
import moment from 'moment'
import { Grid } from '@mui/material'

export const BlogInfoTile = (props) => {
    return (
        <BlogInfoTileWrapper
            onMouseEnter={props.compEntered}
            onMouseLeave={props.compLeave}
            style={
                props.location.pathname.includes("analects") ?
                    {
                        backgroundColor: "rgba(255, 255, 255, 0.6)"
                    }
                    :
                    {}}>
            <a href={`/${props.data.id}`} style={{ textDecoration: "none" }}>
                <FlexBox style={{ alignItems: "normal", display: props.width > 450 ? "flex" : "block" }}>
                    <div>
                        <ImgHolder
                            img={props.data?.dp?.filter((x) => x.type === "dp23")[0].url}
                            width={props.width > 450 ? 200 : "100%"}
                            height={props.width > 450 ? 300 : "100%"}
                        />
                    </div>
                    {
                        props.width > 450
                            ?
                            <SpacerRightLarge />
                            :
                            <SpacerBottomMedium />
                    }
                    <FlexColumnSB>
                        <div>
                            <BlogInfoTileDateText>
                                {moment(props.data?.createdAt.toDate()).format("MMM YYYY")}
                            </BlogInfoTileDateText>
                            <SpacerTopBottomMedium />
                            <BlogInfoTileHeading>
                                {props.data.heading}
                            </BlogInfoTileHeading>
                        </div>
                        <div>
                            <BlogInfoTileDescText>
                                {props.data.description}
                            </BlogInfoTileDescText>
                        </div>
                    </FlexColumnSB>
                </FlexBox>
            </a>
        </BlogInfoTileWrapper>
    )
}
