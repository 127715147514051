import React, { useContext, useEffect, useState } from 'react'
import ScrollToTop from '../../global/components/scrollToTop'
import { Navbar } from '../../global/components/navigation/navbar'
import { motion } from 'framer-motion'
import { AlchemistContainer, AlchemistWrapper } from './style/alchemist.styles'
import { Container, SandwichHalf, SandwichRealHalf, Squeezer } from '../../global/style/global.styles'
import { AlchemistHero } from './components/alchemistHero'
import { BlogTopics } from './components/blogTopics'
import { RecentBlogs } from './components/recentBlogs'
import { Footer } from '../../global/components/footer'
import { ApiContext } from '../../context/api/api.provider'

export const Alchemist = (props) => {

    const { blogs } = useContext(ApiContext)

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const [cursorVariant, setCursorVariant] = useState("default")

    useEffect(() => {
        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", mouseMove);
        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }

        setCursorVariant("default")
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 10,
            y: mousePosition.y - 10,
            mixBlendMode: "difference"
        },
        diffCursor: {
            width: 100,
            height: 100,
            x: mousePosition.x - 50,
            y: mousePosition.y - 50,
            backgroundColor: "white",
            mixBlendMode: "difference"
        },
        normalCursor: {
            width: 0,
            height: 0,
        }
    }

    const compEntered = () => setCursorVariant("diffCursor")
    const compLeave = () => setCursorVariant("default")

    return (
        <AlchemistWrapper>
            <ScrollToTop />
            <Navbar
                color="#826020"
                logoEntered={compEntered}
                logoLeave={compLeave}
                navBarEntered={compEntered}
                navBarLeave={compLeave}
            />
            <SandwichRealHalf>
                <Squeezer>
                    <AlchemistContainer>
                        <AlchemistHero
                            width={props.width}
                            location={props.location}
                            compEntered={compEntered}
                            compLeave={compLeave}
                            blogs={blogs}
                        />
                        <BlogTopics
                            width={props.width}
                            location={props.location}
                            compEntered={compEntered}
                            compLeave={compLeave}
                            blogs={blogs}
                        />
                        <RecentBlogs
                            width={props.width}
                            location={props.location}
                            compEntered={compEntered}
                            compLeave={compLeave}
                            blogs={blogs}
                        />
                    </AlchemistContainer>
                </Squeezer>
            </SandwichRealHalf>
            <Footer
                variant="transparent"
                footerEntered={compEntered}
                footerLeave={compLeave}
                backToTopBtnEntered={compEntered}
                backToTopBtnLeave={compLeave}
                inputFieldEntered={compEntered}
                inputFieldLeft={compLeave}
            />
            {
                props.width > 850
                &&
                <motion.div
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        zIndex: 2,
                        pointerEvents: "none"
                    }}
                    variants={variants}
                    animate={cursorVariant}
                />
            }
        </AlchemistWrapper>
    )
}
