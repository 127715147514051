import React from 'react'
import { BlogViewAllBtnContainer, BlogViewAllBtnText } from '../style/alchemist.styles'
import { AbsoluteCenter } from '../../../global/style/global.styles'

export const BlogViewAllBtn = (props) => {
    return (
        <a href="/analects/all" style={{ textDecoration: "none" }}>
            <BlogViewAllBtnContainer onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}>
                <AbsoluteCenter>
                    <BlogViewAllBtnText>
                        View all
                    </BlogViewAllBtnText>
                </AbsoluteCenter>
            </BlogViewAllBtnContainer>
        </a>
    )
}
