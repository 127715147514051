import React, { useEffect, useRef, useState } from 'react'
import { HeroVideo, NavItemPosition, NavSectionContainer, NavTitle } from './navigation.styles'
import { Layer } from '../layer'
import { AbsoluteBottomRight, AbsoluteCenter, AbsoluteCenterLeft, AbsoluteTopRight, FlexColumnSB } from '../../style/global.styles'
import { LogoBuild } from '../logoBuild'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { motion } from 'framer-motion'
import "./navbar.css"

export const NavSection = (props) => {

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const [cursorVariant, setCursorVariant] = useState("default")

    useEffect(() => {
        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", mouseMove);
        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 10,
            y: mousePosition.y - 10
        },
        diffCursor: {
            width: 80,
            height: 80,
            x: mousePosition.x - 40,
            y: mousePosition.y - 40,
            backgroundColor: "white",
            mixBlendMode: "difference"
        }
    }

    const enteredText = () => setCursorVariant("diffCursor")
    const leaveText = () => setCursorVariant("default")

    // const videoRef = useRef(undefined);
    // useEffect(() => {
    //     videoRef.current.defaultMuted = true;
    // })

    return (
        <>
            <NavSectionContainer>
                <Layer
                    shade={"rgba(0, 0, 0, 0.5)"}
                />
                {/* <HeroVideo
                    ref={videoRef}
                    loop
                    autoPlay
                    muted
                    playsInline
                    poster={welcomeThumb}>
                    <source
                        src={welcomeVideo}
                        type="video/mp4"
                    />
                </HeroVideo> */}
                <NavItemPosition>
                    <FlexColumnSB style={{ height: "100%" }}>
                        <NavTitle
                            to='https://www.inspirise.com/services'
                            target='_blank'
                            onMouseEnter={enteredText}
                            onMouseLeave={leaveText}>
                            SERVICES
                        </NavTitle>
                        <NavTitle
                            to='https://www.inspirise.com/work'
                            target='_blank'
                            onMouseEnter={enteredText}
                            onMouseLeave={leaveText}>
                            OUR WORK
                        </NavTitle>
                        <NavTitle
                            to='https://www.inspirise.com/contact'
                            target='_blank'
                            onMouseEnter={enteredText}
                            onMouseLeave={leaveText}>
                            CONTACT
                        </NavTitle>
                        {/* <NavTitle to='/blog' onMouseEnter={enteredText} onMouseLeave={leaveText}>
                            BLOG
                        </NavTitle> */}
                        <NavTitle
                            to='https://www.inspirise.com/about'
                            target='_blank'
                            onMouseEnter={enteredText}
                            onMouseLeave={leaveText}>
                            ABOUT
                        </NavTitle>
                        {/* <NavTitle
                            to='/'
                            onMouseEnter={enteredText}
                            onMouseLeave={leaveText}>
                            BLOG
                        </NavTitle> */}
                    </FlexColumnSB>
                </NavItemPosition>
                <AbsoluteBottomRight style={{ padding: props.width > 650 ? "0 160px 80px 0" : "0 40px 80px 0" }}>
                    <LogoBuild
                        color="white"
                        width={40}
                        height={40}
                        logoEntered={enteredText}
                        logoLeave={leaveText}
                        link={"https://www.inspirise.com/"}
                        clicked={props.location.pathname === "/" && props.closeDrawer}
                    />
                </AbsoluteBottomRight>
                <AbsoluteTopRight
                    style={{ padding: props.width > 650 ? "80px 160px 0 0" : "40px 40px 0 0" }}
                    onClick={props.closeDrawer}>
                    <div onMouseEnter={enteredText} onMouseLeave={leaveText}>
                        <ChevronRightRoundedIcon
                            sx={{ color: "white", cursor: "pointer" }}
                            fontSize='large'
                        />
                    </div>
                </AbsoluteTopRight>
                <div className='animGradBg' />
                {/* <FlashLightBg /> */}
                <motion.div
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        pointerEvents: "none"
                    }}
                    variants={variants}
                    animate={cursorVariant}
                />
            </NavSectionContainer>
        </>
    )
}
