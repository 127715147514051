import { NavLink } from 'react-router-dom';
import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Container = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Squeezer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
    }
`;

export const SandwichHalf = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
`;

export const SandwichRealHalf = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
`;

export const GenContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};
    
    @media only screen and (min-width: 1800px) {
    }
    @media only screen and (max-width: 1300px) {
    }
    @media only screen and (max-width: 1100px) {
    }
    @media only screen and (max-width: 850px) {
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
    }
    @media only screen and (max-width: 370px) {
    }
`;

// ----------------------------------------FLEX----------------------------------------//

export const FlexBoxSB = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexBoxSA = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FlexBoxSE = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const FlexBox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnSA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexColumnSE = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

// ----------------------------------------ALIGNMENT----------------------------------------//

export const AlignCenter = styled.div`
    text-align: center;
`;

// ----------------------------------------POSITION----------------------------------------//


export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const AbsoluteCenterBottom = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
`;

export const AbsoluteCenterLeft = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
`;

export const AbsoluteBottomRight = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const AbsoluteTopRight = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const AbsoluteLogo = styled.div`
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

// ----------------------------------------FOOTER----------------------------------------//

export const FooterWrapper = styled.div`
    position: relative;
`;

export const FooterContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    position: relative;
    overflow: hidden;
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const FooterItem = styled(NavLink)`
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.title};
    text-decoration: none;

    &:hover {
        transition: all 0.3s ease;
        color: #099B78;
    }
    &:not(:hover) {
        transition: all 0.5s ease;
    }
`;

export const NewsletterText = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSizes.title};
    line-height: 149.5%;
`;

export const PacificoText = styled.div`
    font-family: 'Pacifico';
    font-size: ${(props) => props.theme.fontSizes.title};
    line-height: 149.5%;
`;

export const FooterOthersText = styled(NavLink)`
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    line-height: 150%;
    letter-spacing: 0.04em;
    text-decoration: none;

    ${'' /* &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease;
        color: #9B096A;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    } */}

    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.body};
    }
`;

export const FooterOutlinedText = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 180px;
    font-weight: 900;
    letter-spacing: 0.04em;

    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.headline};
    }
    @media only screen and (max-width: 850px) {
        font-size: 104px;
    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.childHeadline};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
    }
    @media only screen and (max-width: 370px) {
        font-size: 56px;
    }
`;

export const FooterRotatedContainer = styled.div`
    transform: rotate(-0.25turn);
    display: flex;
    align-items: center;
`;

export const FooterRotatedContainerPosition = styled.div`
    position: absolute;
    bottom: 164px;
    right: 50px;

    @media only screen and (min-width: 1800px) {
        right: 135px;
    }
    @media only screen and (max-width: 1300px) {
        right: -20px;
    }
    @media only screen and (max-width: 1100px) {
        right: -50px;
    }
    @media only screen and (max-width: 850px) {
        right: -50px;
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        right: -65px;
    }
    @media only screen and (max-width: 370px) {
        right: -85px;
    }
`;

export const FooterYear = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 72px;
    font-weight: 700;
    letter-spacing: 0.04em;
`;

export const ContactFormInput = styled.input`
    border: 1px solid #D8D9D9;
    width: 400px;
    height: 48px;
    padding: 4px 8px;
    border-radius: 2px;

    @media only screen and (max-width: 1100px) {
        width: 350px;
    }
    @media only screen and (max-width: 850px) {
        width: 100%;
    }
`;


